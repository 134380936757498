@import"Typography";
@import"variables";
@import"_footer";
@import"MapboxMarker";

/* Add application styles & imports to this file! */
@import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

@import "ag-grid-community/src/styles/ag-theme-alpine-dark/sass/_ag-theme-alpine-dark-mixin.scss";

.ag-theme-alpine-dark-custom {
    @include ag-theme-alpine-dark(
        (
            
        // WARNING HAD TO COMMENT LINE 146 IN ag-grid-community/src/styles/ag-theme-base/sass/parts/_grid-layout.scss -> BUG
                     // cell-horizontal-border: solid ag-derived(secondary-borde
            borders: true,
            header-column-separator: true,
            header-column-resize-handle: false,
            header-background-color: rgb(60, 60, 60),
            control-panel-background-color: rgb(60, 60, 60),
            font-family: $font__ROBOTO,
            foreground-color: rgb(220, 220, 220),
            data-color: rgb(220, 220, 220)
        )
    );

    .ag-header {
        // or write CSS selectors to make customisations beyond what the parameters support
        font-size: 14px;
    }
    .ag-row-group {
        align-items: center;
    }
    .ag-paging-panel {
        background: rgb(60, 60, 60);
    }
    .ag-row {
        background: rgb(40, 40, 40);
    }
    .ag-row-odd {
        background: rgb(0, 0, 0);
    }
    .ag-header-cell-text {
        // height: 30px;
    }
    .ag-cell-wrapper.ag-row-group {
        align-items: center;
    }
    .ag-cell {
        align-items: center;
        // line-height: 25px; //Create issues with recalculation
        // height : auto;
        // min-height: 10px;
    }
	.ag-status-bar {
		// min-height: 30px;
		height :25px;
		display : flex;
		align-items: center;
	}
}
html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	-moz-osx-font-smoothing: grayscale;
	font-family: $font__ROBOTO;
	font-size: 16px;
	overflow: hidden;

}

input {
	display: block;
	border: 0px;
	background-color: transparent;
	box-sizing: border-box;
	pointer-Events: all;
	-webkit-appearance: none;
	outline: none;
}

;

input:invalid,
input:focus,
input:active {
	-webkit-appearance: none;
	outline: none;
	border: none;
	box-shadow: none;
}

;



@media only screen and (min-width: $MediaQueryLaptop) {

	html,
	body {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		-moz-osx-font-smoothing: grayscale;
		font-family: $font__ROBOTO;
		font-size: 18px;
		overflow: hidden;
		color: black
	}
}

#root {
	position: relative;
	height: 100%;
	width: 100%;
	// max-width: 2560px;
	margin: 0 auto;
	padding: 0;
	// background-image: url(/images/Backgrounds/32.jpg);
	// background-repeat: no-repeat;
	// background-size: cover;
	// background-position: center;
}

.PageTransitionInitial {
	opacity: 0;
}

.PageTransitionFinal {
	opacity: 1;
	transition: opacity 0.2s ease-in-out;
}

.fade-enter {
	position: absolute;
	opacity: 0;
	// z-index: 1;
	color: rgb(47, 50, 60);
}

.fade-enter.fade-enter-active {
	position: absolute;
	opacity: 1;
	transition: opacity 800ms ease-in;
}